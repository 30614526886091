// Config file
import * as firebase from "firebase";


const firebaseConfig = {
    apiKey: "AIzaSyCaN564Zad20LrMIWCBg7IQDWmYpiXTDLw",
    authDomain: "vamirrors.firebaseapp.com",
    databaseURL: "https://vamirrors.firebaseio.com",
    projectId: "vamirrors",
    storageBucket: "vamirrors.appspot.com",
    messagingSenderId: "694139676651",
    appId: "1:694139676651:web:7734314dc3947744"
  };

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();