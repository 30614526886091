import React from 'react';
import MediaCapturer from 'react-multimedia-capture';
import firebase from './firebase';

// Create a root reference
var storageRef = firebase.storage().ref();
var globaldownloadURL;
var isVideoAvialable = false;
var isFirstVideo = true;
var framevideo_id;
var currentVideoName = 0;

class TabletVideo extends React.Component {

    constructor() {
		super();
		this.state = {
			granted: false,
			rejectedReason: '',
			recording: false,
			paused: false
		};

		this.handleRequest = this.handleRequest.bind(this);
		this.handleGranted = this.handleGranted.bind(this);
		this.handleDenied = this.handleDenied.bind(this);
		this.handleStart = this.handleStart.bind(this);
		this.handleStop = this.handleStop.bind(this);
		this.handlePause = this.handlePause.bind(this);
		this.handleResume = this.handleResume.bind(this);
		this.handleStreamClose = this.handleStreamClose.bind(this);
		this.setStreamToVideo = this.setStreamToVideo.bind(this);
		this.releaseStreamFromVideo = this.releaseStreamFromVideo.bind(this);
		this.downloadVideo = this.downloadVideo.bind(this);
	}
	handleRequest() {
		console.log('Request Recording...');
	}
	handleGranted() {
		this.setState({ granted: true });
		console.log('Permission Granted!');
	}
	handleDenied(err) {
		this.setState({ rejectedReason: err.name });
        console.log('Permission Denied!', err);
        window.confirm("Permission Denied!", err + err.name);
	}
	handleStart(stream) {
		this.setState({
			recording: true
		});

		this.setStreamToVideo(stream);
		console.log('Recording Started.');
	}
	handleStop(blob) {
		this.setState({
			recording: false
		});

		this.releaseStreamFromVideo();

		console.log('Recording Stopped.');
		this.downloadVideo(blob);
	}
	handlePause() {
		this.releaseStreamFromVideo();

		this.setState({
			paused: true
		});
	}
	handleResume(stream) {
		this.setStreamToVideo(stream);

		this.setState({
			paused: false
		});
	}
	handleError(err) {
        console.log(err);
        window.confirm("Error!", err + err.name);
	}
	handleStreamClose() {
		this.setState({
			granted: false
		});
	}
	setStreamToVideo(stream) {


        const {video_id} = this.props;
        console.log(video_id);

        framevideo_id = "frame" + video_id;
        console.log(framevideo_id); 

        let video = document.getElementById(video_id);

            if(window.URL) {
            try {
                video.srcObject = stream;
            } catch (error) {
                this.src = stream;
            }
        }
        else {
            video.srcObject = stream;
        }
	}
	releaseStreamFromVideo() {
		this.refs.app.querySelector('video').src = '';
	}
	downloadVideo(blob) {
		let url = URL.createObjectURL(blob);
		let a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.target = '_blank';
		document.body.appendChild(a);

		a.click();
	}
	render() {
		const granted = this.state.granted;
		const rejectedReason = this.state.rejectedReason;
		const recording = this.state.recording;
		const paused = this.state.paused;

		return (
			<div ref="app">
				<h3>Video Recorder</h3>
				<MediaCapturer
					constraints={{ audio: true, video: true }}
					timeSlice={10}
					onRequestPermission={this.handleRequest}
					onGranted={this.handleGranted}
					onDenied={this.handleDenied}
					onStart={this.handleStart}
					onStop={this.handleStop}
					onPause={this.handlePause}
					onResume={this.handleResume}
					onError={this.handleError} 
					onStreamClosed={this.handleStreamClose}
					render={({ request, start, stop, pause, resume }) => 
					<div>
						<p>Granted: {granted.toString()}</p>
						<p>Rejected Reason: {rejectedReason}</p>
						<p>Recording: {recording.toString()}</p>
						<p>Paused: {paused.toString()}</p>

						{!granted && <button onClick={request}>Get Permission</button>}
						<button onClick={start}>Start</button>
						<button onClick={stop}>Stop</button>
						<button onClick={pause}>Pause</button>
						<button onClick={resume}>Resume</button>
						
						<p>Streaming test</p>
						<video autoPlay></video>
					</div>
				} />
			</div>
		);
	}
}
    
//     // timeTemp;
//     constructor() {
//         super();
//         this.state = {
//             granted: false,
//             rejectedReason: '',
//             recording: false,
//             paused: false,
//             fireID: '',
//             fireData: []

            
//         };

        

//         this.timeStamp = Date.now().toString();

//         this.handleRequest = this.handleRequest.bind(this);
//         this.handleGranted = this.handleGranted.bind(this);
//         this.handleDenied = this.handleDenied.bind(this);
//         this.handleStart = this.handleStart.bind(this);
//         this.handleStop = this.handleStop.bind(this);
//         this.handlePause = this.handlePause.bind(this);
//         this.handleResume = this.handleResume.bind(this);
//         this.handleStreamClose = this.handleStreamClose.bind(this);
//         this.setStreamToVideo = this.setStreamToVideo.bind(this);
//         this.releaseStreamFromVideo = this.releaseStreamFromVideo.bind(this);
//         this.downloadVideo = this.downloadVideo.bind(this);
//     }

//     componentDidMount() {
//         this.getUserParams();
//         this.getUserData();
//     }



//     handleRequest() {
//         console.log('Request Recording...');
//     }
//     handleGranted() {
//         this.setState({ granted: true });
//         console.log('Permission Granted!');
//         // window.confirm("Permission Granted!");

//     }
//     handleDenied(err) {
//         this.setState({ rejectedReason: err.name });
//         console.log('Permission Denied!', err + err.name);
//         window.confirm("Permission Denied!", err + err.name);
//     }
//     handleStart(stream) {
//         this.setState({
//             recording: true
//         });
//         isFirstVideo = false;
//         isVideoAvialable = false
//         this.setStreamToVideo(stream);
//         console.log('Recording Started.');
//     }
//     handleStop(blob) {
//         this.setState({
//             recording: false
//         });
//         isVideoAvialable = true;
//         this.releaseStreamFromVideo();

//         console.log('Recording Stopped.');
//         this.downloadVideo(blob);
//         let url = window.URL.createObjectURL(blob);
//         var iframe = document.getElementById(framevideo_id);
//             iframe.src = url;
//     }
//     handlePause() {
//         this.releaseStreamFromVideo();

//         this.setState({
//             paused: true
//         });
//     }
//     handleResume(stream) {
//         this.setStreamToVideo(stream);

//         this.setState({
//             paused: false
//         });
//     }
//     handleError(err) {
//         console.log(err);
//     }
//     handleStreamClose() {
//         this.setState({
//             granted: false
//         });
//     }
//     setStreamToVideo(stream) {
//         const {video_id} = this.props;
//         console.log(video_id);

//         framevideo_id = "frame" + video_id;
//         console.log(framevideo_id); 

//         let video = document.getElementById(video_id);

//             if(window.URL) {
//             try {
//                 video.srcObject = stream;
//             } catch (error) {
//                 this.src = stream;
//             }
//         }
//         else {
//             video.srcObject = stream;
//         }
//     }
//     releaseStreamFromVideo() {
//         const {video_id} = this.props;
//         document.getElementById(video_id).src = '';
//     }

//     downloadVideo(blob) {
//         let url = window.URL.createObjectURL(blob);
//         let a = document.createElement('a');
//         a.style.display = 'none';
//         a.href = url;
//         a.target = '_blank';
//         document.body.appendChild(a);

//         // a.click();
//         this.uploadsVideo(blob)

//     }

//     uploadsVideo(file){
//         var date = new Date(); 
//         var timestamp = date.getTime();
//         file.name = timestamp;
//         currentVideoName = file.name;
        
//             // Create the file metadata
//             var metadata = {
//             contentType: 'image/jpeg'
//             };

//             // Upload file and metadata to the object 'images/mountains.jpg'
//             var uploadTask = storageRef.child('videos/' + file.name).put(file);

//             // Listen for state changes, errors, and completion of the upload.
//             uploadTask.on('state_changed', // or 'state_changed' or firebase.storage.TaskEvent.STATE_CHANGED
//             function(snapshot) {
//                 // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//                 var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                 console.log('Upload is ' + progress + '% done');
//                 switch (snapshot.state) {
//                 case 'paused': // or 'paused' or firebase.storage.TaskState.PAUSED
//                     console.log('Upload is paused');
//                     break;
//                 case 'running': // or 'running' or firebase.storage.TaskState.RUNNING
//                     console.log('Upload is running');
//                     break;
//                 }
//             }, function(error) {

//             // A full list of error codes is available at
//             // https://firebase.google.com/docs/storage/web/handle-errors
//             switch (error.code) {
//                 case 'storage/unauthorized':
//                 // User doesn't have permission to access the object
//                 break;

//                 case 'storage/canceled':
//                 // User canceled the upload
//                 break;

//                 case 'storage/unknown':
//                 // Unknown error occurred, inspect error.serverResponse
//                 break;
//             }
//             }, function() {
//             // Upload completed successfully, now we can get the download URL
//             uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
//                 console.log('File available at', downloadURL);
//                 globaldownloadURL = downloadURL;

//                 // add url to database
//                 firebase.database().ref('pic_id_to_link').child(currentVideoName).set(downloadURL);
//                 console.log('t'+framevideo_id.substr(5));
                
//                 document.getElementById('num'+framevideo_id.substr(5)).style.webkitTextStrokeColor = "green";
           
//                 // document.getElementById("wrap-videonum").style.color = "green";    
                  
//                 // var iframe = document.getElementById(framevideo_id);
//                 // iframe.src = globaldownloadURL;
//                 // isVideoAvialable = true;
//                 // window.open(downloadURL,'_blank').focus();
//             });
//             });

            
//     }

//     getUserData = () => {
//         let ref = firebase.database().ref('/');
//         ref.on('value', snapshot => {
//             const state = snapshot.val();
//             console.log('state', state);

//             let tempArr = [];
//             for (let s in state){
//                 tempArr.push(state[s])
//             }
//             const devIndex = tempArr.find(data => {
//                 return data.id === this.state.fireID;
//             });
//             document.getElementById('video_1').src = devIndex && devIndex.video_1;
//             document.getElementById('video_2').src = devIndex && devIndex.video_2;

//         });
//     };

//     getUserParams(){
//         var url = new URL(window.location);
//         var c = url.searchParams.get("fire");
//         this.setState({fireID: c});
//     }


    

//     render() {
//         const {video_id} = this.props;
//         const granted = this.state.granted;
//         const rejectedReason = this.state.rejectedReason;
//         const recording = this.state.recording;
//         const videoavailable = false;
//         const paused = this.state.paused;
        

//         return (
//             <div >
//                 {/*<input type={'file'} onChange={this.uploadsVideo}/>*/}
//                 <MediaCapturer
//                     constraints={{ audio: false, video: true }}
//                     timeSlice={10}
//                     onRequestPermission={this.handleRequest}
//                     onGranted={this.handleGranted}
//                     onDenied={this.handleDenied}
//                     onStart={this.handleStart}
//                     onStop={this.handleStop}
//                     onPause={this.handlePause}
//                     onResume={this.handleResume}
//                     onError={this.handleError}
//                     onStreamClosed={this.handleStreamClose}
//                     render={({ request, start, stop, pause, resume }) =>
//                         <div className="video-wrapper">
//                             {/*<p>Granted: {granted.toString()}</p>*/}
//                             {/*<p>Rejected Reason: {rejectedReason}</p>*/}
//                             {/*<p>Recording: {recording.toString()}</p>*/}
//                             {/*<p>Paused: {paused.toString()}</p>*/}

//                             {/* {!granted && <button onClick={request()}>Get Permission</button>} */}

//                             {/* <img className={'imgwrapper'} hidden={!isFirstVideo}  src={require('./addglasses.jpeg')} onClick={start}/> */}
//                             <img className={'tabletimgwrapper'} hidden={!isFirstVideo} onClick={start} src={require('./ADDGLASSES.png')}/>
//                             <video className={'tabletvideowrapper'} id={video_id} hidden={isVideoAvialable || isFirstVideo}  autoPlay/>
            
//                             {/* {<iframe className={'iframevideo'} id={framevideo_id}  hidden={!isVideoAvialable} src='about:blank' autoplay loop muted/>} */}
//                             <video className={'tabletvideowrapper'} id={framevideo_id} hidden={!isVideoAvialable}  onloadedmetadata="this.muted=true" autoPlay loop><source src={globaldownloadURL} type="video/mp4"/></video>
//                             <text id={'t'+video_id} src={currentVideoName} hidden={true}>{currentVideoName}</text>
//                             <div className={'wrap-videonum'} id={'num'+video_id}>
//                                 {video_id.substr(6)}
//                             </div>
                            
//                             <div className={'wrap-buttons'}>
//                                 {/* <img className={'startrecord-button'} hidden={!isFirstVideo} onClick={start} src={require('./recordpng.png')}/>   */}
//                                 <img className={'startrecord-button'} hidden={recording || isFirstVideo} onClick={start} src={require('./renewpng.png')}/>
//                                 <img className={'stoprecord-button'} hidden={!recording} onClick={stop} src={require('./eSTOPRECORD.png')}/>
//                                 {!granted && <button onClick={request()}>Get Permission</button>}
                                
//                                 {/* <button className={'stoprecord-button'} hidden={!recording} onClick={stop}>&#10074;&#10074;</button> */}


//                                 &nbsp;
                                
//                                 {/* <button className={'playvideo-button'} hidden={recording} onClick={playVideos}>&#9658;</button> */}
                            
//                             </div>
                            
//                             {/* {<span>{globaldownloadURL}</span>} */}
//                             {/* {<iframe src={globaldownloadURL}/>} */}
//                             {/*<button onClick={pause}>Pause</button>*/}
//                             {/*<button onClick={resume}>Resume</button>*/}
//                         </div>
                        
//                     } />
//             </div>

            
//         );
//     }
// }


function playVideos(){
    var iframe = document.getElementById(framevideo_id);
    iframe.src = iframe.src;
}

export default TabletVideo;