import React from 'react';
import Video from './Video/Video';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from './Video/firebase';

const Mobile = () => {
    return (
        <div className={'App'}>
        <div className="container">
          
                  

          <div className="row">
            <div className="col-12">
                <br></br>
                <font size="5"><b>Record, review and</b></font>
                <br></br>
                <font size="5"><b>compare your glasses</b></font>
                <br></br><br></br>
           </div>
          </div>

          <div className="row">
              <div className="col-6 nopadding">
                  <Video video_id={'video_1'} />
              </div>

              <div className="col-6 nopadding">
                  <Video video_id={'video_2'} />
              </div>
          </div>

          <div className="row">
              <div className="col-6 nopadding">
                  <Video video_id={'video_3'} />
              </div>

              <div className="col-6 nopadding">
                  <Video video_id={'video_4'} />
              </div>
          </div>

          <div className="row">
          <div className="col-4">
                  <br></br>
                  {/* <a href="whatsapp://send?text=https://relitach.github.io/va?">
                  <button className={'share-button'}>Share</button> */}
                  <img className={'back-button'} src={require('./Video/eBACK.png')} onClick={refreshPage}/>
                  <br></br>
                  <font size="2">New session</font>
                </div>
              <div className="col-8 text-left">
                  <br></br>
                  {/* <a href="whatsapp://send?text=https://relitach.github.io/va?">
                  <button className={'share-button'}>Share</button> */}
                  &nbsp;&nbsp;&nbsp;&nbsp;<img className={'share-button'} src={require('./Video/sharepng.png')} onClick={getUrlString}/>
                  <br></br>
                  &nbsp;&nbsp;<font size="4"><b>Share with</b></font>
                  <br></br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<font size="4"><b>friends</b></font>
                  <br></br>
                </div>
          </div>


          <div className="row">
              <div className="col-6 text-left">
                  <font size="2">Power by VAmirrors</font>
                </div>
                <div className="col-6 text-right">
                <a href="https://www.vamirrors.com/privacy-policygeneral-terms"><font color="FFFFFF" size="2">Terms of use</font></a>
                </div>
          </div>
                  
        </div>

        

        {/* <button className={'playvideo-button'}  onClick={playVideos}>&#9658;</button> */}
        {/* <!-- The core Firebase JS SDK is always required and must be listed first --> */}
        <script src="/__/firebase/6.3.4/firebase-app.js"></script>
        {/* <!-- TODO: Add SDKs for Firebase products that you want to use
                    https://firebase.google.com/docs/web/setup#reserved-urls -->
                <!-- Initialize Firebase --> */}
        <script src="/__/firebase/init.js"></script>
      </div>
    );
}
 
function refreshPage() {
  window.location.reload(false);
}

function getUrlString() {

  
    var t1 = document.getElementById("tvideo_1").textContent;
    var t2 = document.getElementById("tvideo_2").textContent;
    var t3 = document.getElementById("tvideo_3").textContent;
    var t4 = document.getElementById("tvideo_4").textContent;
  
    var urlstring="whatsapp://send?text=https://relitach.com/va/"+'?va='+t1+'%26vb='+t2+'%26vc='+t3+'%26vd='+t4;
    // alert(urlstring);
    // window.open(urlstring);
    var stringids = t1+';'+t2+';'+t3+';'+t4;
    var encodedStringids = btoa(stringids);
  
  
    const BitlyClient = require('bitly').BitlyClient;
    const bitly = new BitlyClient('29a7e82f2a1bcbc9fd3ee4c56c4790ed0443ed2c');
    
    var resulturlbitly;
    
    bitly
      .shorten("https://relitach.com/va/"+'?va='+encodedStringids)
      .then(function(result) {
        console.log(result);
        resulturlbitly = result;
        console.log("https://relitach.com/va/"+'?va='+encodedStringids);
        console.log(resulturlbitly.url);
      
        if (navigator.share) {
          console.log("Congrats! Your browser supports Web Share API")
        
          // navigator.share accepts objects which must have atleast title, text or
          // url. Any text or title or text is possible
          navigator.share({
                  title: "VA Mirrors",
                  text: "Hi, help me choose a new pair of glasses",
                  // url: "https://vamirrors.com/"+'?va='+encodedStringids
                  url: resulturlbitly.url + ""
              })
              .then(function () {
                  console.log("Shareing successfull")
              })
              .catch(function () {
                  console.log("Sharing failed")
              })
        
        } else {
        
          console.log("Sorry! Your browser does not support Web Share API");
          // window.open("https://vamirrors.com/"+'?va='+t1+'&vb='+t2+'&vc='+t3+'&vd='+t4);
          window.open("https://relitach.com/va/"+'?va='+btoa(t1+';'+t2+';'+t3+';'+t4));
        }
  
  
      })
      .catch(function(error) {
        console.error(error);
      });
  
  // if (navigator.share) {
  //   console.log("Congrats! Your browser supports Web Share API")
  
  //   // navigator.share accepts objects which must have atleast title, text or
  //   // url. Any text or title or text is possible
  //   navigator.share({
  //           title: "VA Mirrors",
  //           text: "היי, מה קורה ? מה לדעתך מתאים לי יותר ?",
  //           // url: "https://vamirrors.com/"+'?va='+encodedStringids
  //           url: resulturlbitly + ""
  //       })
  //       .then(function () {
  //           console.log("Shareing successfull")
  //       })
  //       .catch(function () {
  //           console.log("Sharing failed")
  //       })
  
  // } else {
  
  //   console.log("Sorry! Your browser does not support Web Share API");
  //   window.open("https://vamirrors.com/"+'?va='+t1+'&vb='+t2+'&vc='+t3+'&vd='+t4);
  // }
  
  
  
  
  
  }
  
  function playVideos() {
  
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('va');
    alert(myParam);
  
    var database = firebase.database();
    database.ref('/pic_id_to_link/' + myParam).once('value').then(function(snapshot) {
    var videoaURL = snapshot.val();});
  
    var iframe = document.getElementById("framevideo_1");
    console.log(iframe);
    if (iframe != null)
      iframe.src = iframe.src;
  
    iframe = document.getElementById("framevideo_2");
    console.log(iframe);
    if (iframe != null)
      iframe.src = iframe.src;
  
    iframe = document.getElementById("framevideo_3");
    console.log(iframe);
    if (iframe != null)
      iframe.src = iframe.src;
  
    iframe = document.getElementById("framevideo_4");
    console.log(iframe);
    if (iframe != null)
      iframe.src = iframe.src;
  }

export default Mobile;