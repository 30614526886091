import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';

import Mobile from './components/Mobile';
import Tablet from './components/Tablet';
import Test from './components/Test';
import Sunopticals from './components/sunopticals';
import Ios from './components/Ios';
import Error from './components/Error';
import Navigation from './components/Navigation';
 
class App extends Component {
  render() {
    return (      
       <HashRouter>
        <div>
          
            <Switch>
             {/* <Route path="/" component={Mobile} exact/> */}
             <Route path="/" component={Tablet} exact/>
             <Route path="/sunopticals" component={Sunopticals}/>
             <Route path="/test" component={Test}/>
             <Route path="/ios" component={Ios}/>
             {/* <Route path="/tablet" component={Tablet}/> */}
            <Route component={Error}/>
           </Switch>
           {/* <Navigation/> */}
        </div> 
      </HashRouter>
    );
  }
}
 
export default App;